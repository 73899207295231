/**
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */
'use strict';

import PropTypes from 'prop-types';
import './../stylesheets/icon-button.less';

const IconButton = ({children, className, ...props}) => {
  const classNames = ['icon-button'];

  if (className) {
    classNames.push(className);
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button className={classNames.join(' ')} {...props}>
      {children}
    </button>
  );
};

IconButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default IconButton;
